import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { updateCastling } from '../actions/castling';
import { isCastlingAvailable } from '../util/castlingHelper';

class AnalysisCastlingCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  handleCheckbox(event) {
    const { castling, dispatch } = this.props;
    const { value, checked } = event.target;

    const castlingCopy = { ...castling };

    if (checked === true && !isCastlingAvailable(chessground.state.pieces, value)) {
      alert('Castling unavailable for this side');
      castlingCopy[value] = false;
    } else {
      castlingCopy[value] = checked;
    }

    dispatch(updateCastling(chessground, castlingCopy));
  }

  render() {
    const { value, label, castling } = this.props;
    return (
      <div className="form-check form-check-inline">
        <input
          id={`${value}-castling`}
          className="form-check-input"
          type="checkbox"
          value={value}
          checked={castling[value]}
          onChange={this.handleCheckbox}
        />
       <label class="form-check-label">
        {label}
       </label>
      </div>
    );
  }
}

AnalysisCastlingCheckbox.propTypes = {
  dispatch: PropTypes.func.isRequired,
  castling: PropTypes.shape({
    K: PropTypes.bool,
    Q: PropTypes.bool,
    k: PropTypes.bool,
    q: PropTypes.bool,
  }),
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const { castling } = state;

  return { castling };
}

export default connect(mapStateToProps)(AnalysisCastlingCheckbox);
