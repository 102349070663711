import axiosCSRF from '../util/axiosCSRF';

export const RECEIVE_POSITION = 'RECEIVE_POSITION';

function receivePosition(json) {
  const { position } = json;
  const moveNum = position.moves.length - 1;
  const orientation = position.orientation === 'w' ? 'white' : 'black';

  return {
    type: RECEIVE_POSITION,
    position,
    moveNum,
    orientation,
  };
}

export function createOrUpdatePosition(position, json) {
  if (isEditMode()) {
    return (dispatch) => dispatch(updatePosition(position, json))
  } else {
    return (dispatch) => dispatch(createPosition(json))
  }
}

export function getPosition(positionId) {
  if (!isEditMode()) {
    window.history.pushState('new', 'Analysis', '/analysis/new');
  }
  const url = getUrl(`/api/v1${window.location.pathname}`);


  return (dispatch) => axiosCSRF
    .get(url)
    .then((response) => dispatch(receivePosition(response.data)));
}

export function isEditMode() {
  return window.location.pathname.includes('edit')
}

// removes admin, replace position with analysis
function getUrl(url) {
  return url.replace('admin/', '/').replace('positions', 'analysis');
}

function createPosition(json) {
  return (dispatch) => axiosCSRF
    .post('/api/v1/analysis', json)
    .then((response) => {
      window.history.pushState('edit', 'Analysis', `/analysis/${response.data.position.id}/edit`);
      dispatch(receivePosition(response.data))
    })
}

function updatePosition(position, json) {
  const url = `/api/v1/analysis/${position.id}`;

  return (dispatch) => axiosCSRF
    .put(url, { position: json })
    .then((response) => dispatch(receivePosition(response.data)));
}
