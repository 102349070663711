import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CHESSJS } from '../constants/constants';
import AnalysisNavButtons from './AnalysisNavButtons';
import { createFen, updateFen } from '../actions/fen';
import { createOrUpdatePosition } from '../actions/position';
import { updateCastling } from '../actions/castling';
import { updateMoveNum } from '../actions/moveNum';
import { isEmpty } from '../util/utils';
import { makeMove } from '../util/chessHelper';
import { getCastlingString } from '../util/castlingHelper';


class AnalysisControls extends React.Component {

  constructor(props) {
    super(props);
    this.handleCalculate = this.handleCalculate.bind(this);
    this.makePendingMoves = this.makePendingMoves.bind(this);
    this.clearArrows = this.clearArrows.bind(this);
    this.drawArrows = this.drawArrows.bind(this);
  }

  handleCalculate(event) {
    this.clearArrows();
    const color = event.target.value;
    const { castling, currentUser, dispatch } = this.props;

    const fen_string = [chessground.getFen(), color, getCastlingString(castling), '-', '0', '1'].join(' ');
    CHESSJS.load(fen_string);

    let numPendingMoves = currentUser.move_automatically === true ? 1 : 0;

    dispatch(createFen(dispatch, fen_string, numPendingMoves));
  }

  makePendingMoves() {
    const { dispatch, fen, position } = this.props;
    const { pendingMoves } = fen;

    if (isEmpty(pendingMoves)) return;

    CHESSJS.load(fen.id);
    chessground.set({ fen: CHESSJS.fen() });

    const newFens = [];

    // Helper function to process each move with a 1 second delay
    const processMoves = (index) => {
      if (index >= pendingMoves.length) {
        // After all moves are processed, dispatch the updates
        dispatch(updateFen(fen, 0)); // clear pending moves
        dispatch(updateCastling(chessground));

        const json = { moves: position.moves.concat(newFens) };
        dispatch(createOrUpdatePosition(position, json));
        dispatch(updateMoveNum(json.moves.length - 1));
        this.drawArrows();
      }

      // Make the move and store the updated FEN
      const pendingMove = pendingMoves[index];
      makeMove(pendingMove, CHESSJS, chessground);
      newFens.push(CHESSJS.fen().split(' ')[0]);

      // Set a 1 second delay before processing the next move
      setTimeout(() => {
        processMoves(index + 1);
      }, 500);
    };

    // Start processing moves from the first one
    processMoves(0);

  }

  clearArrows() {
    if (typeof chessground === 'undefined') return;

    chessground.setShapes([]);
  }

  drawArrows() {
    const { sequence } = this.props.fen?.analysis;
    if(isEmpty(sequence)) return;

    let orig, dest, text, color;
    const shapeSet = [];
    const shapeColors = ['green', 'blue', 'paleGreen', 'paleBlue'];
    const numArrows = Math.min(...[sequence.length, shapeColors.length]);
    for (let i = 0; i < numArrows; i++) {
      [orig, dest] = sequence[i].match(/.{1,2}/g);
      text = (i + 1).toString();
      color = shapeColors[i];
      shapeSet.push({ orig, dest, brush: shapeColors[i], label: { fill: color, text } });
    }

    chessground.setShapes(shapeSet);
  }

  render() {
    const {
      flipBtn,
      resetBtn,
      clearBtn,
      children,
      Settings,
    } = this.props;

    this.makePendingMoves();

    return (
      <div className="card">
        <div className="card-body">
          <div className="d-grid gap-2">
            <button type="button" className="btn btn-outline-secondary" onClick={this.handleCalculate} value="w">
              ♔ White to Move
            </button>
            <button type="button" className="btn btn-dark" onClick={this.handleCalculate} value="b">
              ♚ Black to Move
            </button>
          </div>
          {children}
          <div className="clearfix" />
          <AnalysisNavButtons />
          <br />
          <br />
          {flipBtn}
          {resetBtn}
          {clearBtn}
          {Settings}
          <div className="clearfix" />
        </div>
      </div>
    );
  }
}

AnalysisControls.propTypes = {
  dispatch: PropTypes.func,
  children: PropTypes.node.isRequired,
  Settings: PropTypes.node.isRequired,
  flipBtn: PropTypes.node.isRequired,
  resetBtn: PropTypes.node.isRequired,
  clearBtn: PropTypes.node.isRequired,
};

function mapStateToProps(state) {
  const { castling, currentUser, position, fen } = state;

  return {
    fen,
    castling,
    currentUser,
    position,
  };
}

export default connect(mapStateToProps)(AnalysisControls);
