import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CHESSGROUND_CONFIG } from '../constants/constants';
import { Chessground } from 'chessground';
import { eventPosition } from 'chessground/dist/util';
import AnalysisSparePiece from './AnalysisSparePiece';

class AnalysisBoard extends React.Component {
  static allowDrop(event) {
    event.preventDefault();
  }

  constructor(props) {
    super(props);
    this.board = React.createRef();
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {
    const { config, cgEventChange } = this.props;
    const chessground = Chessground(this.board.current, config);

    chessground.set({
      events: { change: cgEventChange },
    });

    window.chessground = chessground;
    // when window orientation is flipped
    window.addEventListener('orientationchange', () => {
      chessground.redrawAll();
    }, false);

    // when window is resized
    window.addEventListener('resize', () => {
      chessground.redrawAll();
    }, false);
  }

  handleDrop(event) {
    event.preventDefault();
    const { cgEventChange } = this.props;

    const elementId = event.dataTransfer.getData('text');
    const sparePiece = document.getElementById(elementId);
    const pos = eventPosition(event);
    const key = chessground.getKeyAtDomPos(pos);

    const pieces = new Map();
    pieces.set(
      key,
      {
        color: sparePiece.getAttribute('data-color'),
        role: sparePiece.getAttribute('data-piece'),
        promoted: false,
      }
    );

    // use chessground.setPieces instead of chessground.newPiece because newPiece will not replace an existing piece
    chessground.setPieces(pieces);
    cgEventChange();
  }

  render() {
    const { orientation } = this.props;
    const spareTop = orientation === 'white' ? 'black' : 'white';
    const spareBottom = orientation;

    return (
      <div className="board-editor">
        <div className="text-center p-1">
          <AnalysisSparePiece color={spareTop} piece="king" />
          <AnalysisSparePiece color={spareTop} piece="queen" />
          <AnalysisSparePiece color={spareTop} piece="rook" />
          <AnalysisSparePiece color={spareTop} piece="bishop" />
          <AnalysisSparePiece color={spareTop} piece="knight" />
          <AnalysisSparePiece color={spareTop} piece="pawn" />
        </div>

        <div className="brown cg-wrap">
          <div
            ref={this.board}
            onDragOver={AnalysisBoard.allowDrop}
            onDrop={this.handleDrop}
          />
        </div>
        <div className="text-center p-2">
          <AnalysisSparePiece color={spareBottom} piece="king" />
          <AnalysisSparePiece color={spareBottom} piece="queen" />
          <AnalysisSparePiece color={spareBottom} piece="rook" />
          <AnalysisSparePiece color={spareBottom} piece="bishop" />
          <AnalysisSparePiece color={spareBottom} piece="knight" />
          <AnalysisSparePiece color={spareBottom} piece="pawn" />
        </div>
      </div>
    );
  }
}

const additionalChessgroundConfig = {
  fen: 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1',
  turnColor: 'white',
  movable: {
    free: true, // all moves are valid - board editor
    color: 'both', // color that can move. white | black | both |
  },
  draggable: {
    deleteOnDropOff: true,
  },
  drawable: {
    brushes: {
      green: { key: 'g', color: '#15781B', opacity: 1, lineWidth: 12 },
      paleGreen: { key: 'pg', color: '#15781B', opacity: 0.4, lineWidth: 4 },
      blue: { key: 'b', color: '#003088', opacity: 1, lineWidth: 12 },
      paleBlue: { key: 'pb', color: '#003088', opacity: 0.4, lineWidth: 4 },
      red: { key: 'r', color: '#882020', opacity: 1, lineWidth: 12 },
      yellow: { key: 'y', color: '#e68f00', opacity: 1, lineWidth: 12 },
    },
  },
};

// const shapeSet1 = [
//   { orig: 'a1', brush: 'blue' },
//   { orig: 'a5', brush: 'yellow' },
//   { orig: 'a6', brush: 'red' },
//   { orig: 'e2', dest: 'e4', brush: 'green' },
//   { orig: 'a6', dest: 'c8', brush: 'red' },
//   { orig: 'b6', dest: 'd8', brush: 'blue' },
//   { orig: 'f8', dest: 'f4', brush: 'yellow' },
//   { orig: 'h5', brush: 'green', piece: {
//     color: 'white',
//     role: 'knight'
//   }},
//   { orig: 'h6', brush: 'red', piece: {
//     color: 'black',
//     role: 'queen',
//     scale: 0.6
//   }}
// ];

AnalysisBoard.propTypes = {
  config: PropTypes.shape({}),
  cgEventChange: PropTypes.func,
};

AnalysisBoard.defaultProps = {
  config: { ...CHESSGROUND_CONFIG, ...additionalChessgroundConfig },
  cgEventChange: null,
};

function mapStateToProps(state) {
  let orientation = state.position.orientation;
  orientation ||= "w"

  // we only need orientation, not the whole position object
  return { orientation: orientation === 'w' ? 'white' : 'black' };
}

export default connect(mapStateToProps)(AnalysisBoard);
